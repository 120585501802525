import React from "react";
import Navigation from "../components/navbar";
import '../public/about.css'
import about_img from '../public/images/about-img.png';
import Fade from 'react-reveal/Fade'
import link from '../public/images/linkedin-icon.png'
import github from '../public/images/github-icon.svg'

export default function About(){
    return(
        <div className="about">
            {/* <Fade top>
                <nav className="about-nav" style={{boxShadow:'0px 2px 6px 3px grey'}}>
                    <Navigation />
                </nav>
            </Fade> */}
            <div className="about-body d-flex flex-row justify-content-end">
                <Fade top className="d-flex flex-column flex-wrap">
                    <img className='about-image' src={about_img} alt="about image" />
                </Fade>
                <div className="d-flex flex-column flex-wrap align-items-end">
                    <div className="about-text d-flex flex-column align-items-end">
                        <h1 className="about-header d-flex flex-row justify-content-start">About Me</h1>
                        <Fade top>
                            <p>
                                I am a Filipino student attending San Jose State University in the Bachelor of Science Software Engineering Pathway. I currently possess 4+ years of experience in computer science with 2+ years in web development and 3+ years of experience in cyber security.  
                            </p>
                            <p>
                                I prioritize pursuing software projects that I feel passionate about and that can scale to different scopes of communities.
                            </p>
                            <p>
                                I value the significance of team work and leading others who aspire towards goals similar to mine whether through software engineering or general team oriented opportunities. 
                            </p>
                            <p>
                                I am excited to connect with anyone and everyone and will always be ready to dive into the deep end of any adversity in front of me!
                            </p>
                            <div className="d-flex flex-row" style={{gap: '5px'}}>
                                <a href="https://www.linkedin.com/in/marlon-burog-27a46a186/"><img className='about-link about-linked' src={link} /></a>
                                <a href="https://github.com/4-gent"><img className='about-link about-git' src={github} style={{width: '3.5vw'}}/></a>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    )
}
