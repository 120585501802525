import React, { useState } from 'react'
import { NotificationManager, NotificationContainer } from 'react-notifications'
import axios from 'axios'
import '../public/contact.css'
import Navigation from '../components/navbar'
import 'react-notifications/lib/notifications.css';
import ContactImage from '../public/images/contact-image.jpg';
import Fade from 'react-reveal/Fade'

export default function Contact(){
    const [clientName, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const submitHandler = async(e) => {
        e.preventDefault()
        try{
            if(clientName.trim().length === 0)
                NotificationManager.info('Please insert your name', 'Field Empty');
            if(email.trim().length === 0)
                NotificationManager.info('Please insert your email', 'Field Empty');
            if(message.trim().length === 0)
                NotificationManager.info('Please insert your message', 'Field Empty')
            if(clientName.trim().length !== 0 && email.trim().length !== 0 && message.trim().length !== 0){
                NotificationManager.success('Thank you! I will get back to you soon!', 'Message Sent')
                await axios.post('/send_message', {
                    clientName, email, message
                })
            }
        }catch(err){
            console.log(err)
            NotificationManager.error('There has been an error sending your message, please try again', 'Error')
        }
    }

    return(
        <div className='contact'>
            {/* <Fade top>
                <nav className="contact-nav" style={{boxShadow:'0px 2px 6px 3px grey'}}>
                    <Navigation />
                </nav>
            </Fade> */}
            <div className='contact-page-container d-flex flex-wrap flex-row justify-content-evenly'>
                <div className='contact-image d-flex flex-column'>
                    <img className='contact-image-source' src={ContactImage} alt='image' />
                </div>
                <div className='contact-container d-flex flex-column align-items-center'>
                    <h3 className='contact-header'>Contact Me</h3>
                    <form className="contact-form" onSubmit={submitHandler} action='/send_message' method='POST'>
                        <Fade top><input className='form-input-name form-input' placeholder="Name" label='clientName' type='clientName' value={clientName} onChange={(e) => setName(e.target.value)}></input></Fade>
                        <Fade top><input className='form-input' placeholder="Email" label='email' type='email' value={email} onChange={(e) => setEmail(e.target.value)}></input></Fade>
                        <Fade top><textarea className='form-input form-message' placeholder="Message" label='message' type='message' value={message} onChange={(e) => setMessage(e.target.value)}></textarea></Fade>
                        <br />
                        <Fade top><button className='form-button' type='submit'>Submit</button></Fade>
                    </form>
                </div>
            </div>

            <NotificationContainer />
        </div>
    )
}