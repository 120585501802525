import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './main';
import Projects from './routes/Projects';
import About from './routes/About';
import Contact from './routes/Contact';
import NewProject from './routes/NewProject';
import Login from './routes/login';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="*" element={<Main />} />
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/new-project" element={<NewProject />} />
      </Routes>
    </Router>
  );
}

export default App;
