import React, { useState } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import axios from "axios";
import NewProject from "./NewProject";
import Navigation from "../components/navbar";

/**
 * Represents the Login component.
 * @returns {JSX.Element} The Login component.
 */
export default function Login(){
    const [login, setLogin] = useState({ usr: "", pwd: "" });
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    /**
     * Handles the login form submission.
     * @param {Event} e - The form submission event.
     */
    const handleLogin = async(e) =>{
        e.preventDefault();
        try{
            const response = await axios.post('/login', login);
            if(response.data === true){
                NotificationManager.success('Rerouting to New Project', 'Login Success');
                setIsLoggedIn(true);
            }
            if(response.data === false || response.status === 401 || response.data === null){
                NotificationManager.error('Check username and password and try again', 'Login Failed');
            }
        }catch(err){
            NotificationManager.error('Check username and password and try again', 'Login Failed');
            console.log(err);
        }
    }

    return(
        <div>
            {isLoggedIn ? (
                <NewProject />
            ) : (
                <div>
                    <nav>
                        <Navigation />
                    </nav>
                    <h1 className="d-flex flex-column align-items-center">Login Page for Mj Burog</h1>
                    <br />
                    <form className='d-flex flex-column align-items-center' onSubmit={handleLogin}>
                        <input required placeholder='username' type='text' value={login.usr} onChange={(e) => setLogin({...login, usr: e.target.value})} />
                        <input required placeholder='password' type='password' value={login.pwd} onChange={(e) => setLogin({...login, pwd: e.target.value})} />
                        <button type="submit">Login</button>
                    </form>
                    <NotificationContainer />
                </div>
            )}
        </div>
    )
}