import React from "react";
import '../public/projcard.css'
import Fade from 'react-reveal/Fade'

class ProjectCard extends React.Component{
    render() {
        return(
            <Fade top>
                <div className="mdb-card">
                    <img className="project-image" src={this.props.image} alt='...' />
                    <br />
                    <div>
                        <p className="card-title">{this.props.title}</p>
                        <p className="card-text">{this.props.description}</p>
                        <button className="link-button"><a href={`https://${this.props.github}`}>Project Link</a></button>
                    </div>
                </div>
            </Fade>
        )
    }
}

export default ProjectCard;