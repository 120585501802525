import React from 'react';
import Navigation from './components/navbar';
import './public/main.css';
import Fade from 'react-reveal/Fade'
import github from './public/images/github-icon.svg'
import link from './public/images/linkedin-icon.png'
import About from './routes/About';
import Contact from './routes/Contact';
import Projects from './routes/Projects';
import profile from './public/images/profilePic.png'

export default function Main(){
    return(
        <div className='home'>
            <div  className='navbar-main'>
                <Fade top>
                    <Navigation />
                </Fade>
            </div>
            <div className='header-container'>
                <img src={profile} className='main-image' />
                <div className='header'>
                    <h1 className='h1-name'>Marlon "Mj" Burog</h1>
                    <Fade top>
                        <h2 className='h2-university'>San Jose State University</h2>
                        <h3 className='h3-major'>Software Engineering</h3>
                        <p className='p-special'>Specializing in Web Dev and Cyber Security</p>
                        <div className="d-flex flex-row" style={{gap: '5px'}}>
                            <a href="https://www.linkedin.com/in/marlon-burog-27a46a186/"><img className='main-link main-linked' src={link} /></a>
                            <a href="https://github.com/4-gent"><img className='main-link main-git' src={github} style={{width: '3.5vw'}}/></a>
                        </div>
                    </Fade>
                </div>
            </div>
            <div className='bottom-section'>
                <div className='project-section' id='project-section'><Projects /></div>
                <div className='about-section' id='about-section'><About /></div>
                <div className='contact-section' id='contact-section'><Contact /></div>
            </div>
        </div>
    )
}