import React, {useState}from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../public/navbar.css';
import Logo from '../public/images/logo.svg'
import { Link } from 'react-scroll';

export default function Navigation(){
    const [navbarActive, setNavbarActive] = useState(false);

    const handleToggle = () =>{
        setNavbarActive(!navbarActive);
    }

    return(
        <div className='navigation'>
            <Navbar collapseOnSelect expand='lg' className={`navbar ${navbarActive ? 'active' : ''}`} onToggle={handleToggle}>
                <Container fluid>
                    <Navbar.Brand className="nav-title" href='/'><img src={Logo} alt='logo' height={30}/></Navbar.Brand>
                    <Navbar.Toggle className='toggler' aria-controls='responsive-navbar-nav'></Navbar.Toggle>
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav className='ms-auto'>
                            <Link to='project-section' smooth={true} duration={1000} className='nav-buttons' href='/projects'>Projects</Link>
                            <Link to='about-section' smooth={true} duration={1000} className='nav-buttons' href='/about'>About Me</Link>
                            <Link to='contact-section' smooth={true} duration={1000} className='nav-buttons' href='/contact'>Contact Me</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}