import React, { useEffect, useState } from "react";
import ProjectCard from "../components/ProjCard";
import '../public/projects.css'
import axios from "axios";

/**
 * Renders the Projects component.
 * Fetches data from the backend and displays a carousel of project images and project cards.
 *
 * @returns {JSX.Element} The rendered Projects component.
 */
export default function Projects(){
    //useState to store projects data
    const [projects, setProjects] = useState([]);

    //axios to fetch data from backend
    // useEffect to fetch data from backend and update the 'projects' state
    useEffect(() => {
        axios.get('/api/collection')
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <div className="projects">
            {/* <nav className="project-nav" style={{boxShadow:'0px 2px 6px 3px grey'}}>
                <Navigation />
            </nav> */}
            <div className="project-container d-flex flex-column align-content-center">
                <div className="d-flex flex-row justify-content-center" style={{marginTop: '5vh', marginBottom: '2vh'}}>
                    <h1 className="project-header" style={{fontWeight: 'bold'}}>Projects</h1>
                </div>
                    <br />
                    <div className="project-cards" style={{ rowGap: '25px' }}>
                        {projects.map((project) => (
                            <ProjectCard
                                key={project._id}
                                title={project.title}
                                description={project.description}
                                github={project.github}
                                image={`/image/${project.image}`}
                            />
                        ))}
                    </div>
            </div>
        </div>
    )
}