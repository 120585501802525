import React, { useEffect, useState } from "react";
import axios from "axios";
import { NotificationManager, NotificationContainer } from 'react-notifications'
import Navigation from "../components/navbar";

/**
 * Represents the NewProject component.
 * @returns {JSX.Element} The NewProject component.
 */
export default function NewProject() {
    //use states to handle new project and projects data
    const [newProject, setNewProject] = useState({ title: "", description: "", github: "" });
    const [projects, setProjects] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const [image, setImage] = useState(null);

    /**
     * Handles the submission of a new project.
     * @param {Event} e - The form submission event.
     * @returns {Promise<void>} A promise that resolves when the project is successfully inserted.
     */
    const handleNewProject = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('title', newProject.title);
            formData.append('description', newProject.description);
            formData.append('github', newProject.github);

            if(image)
                formData.append('image', image, image.name);

            await axios.post('/new-project', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            NotificationManager.success('Refreshing the page', 'Project Inserted')
            console.log(newProject);
            window.location.href = '/new-project';
            setRefresh(true)
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * Handles the deletion of a project.
     * @param {Event} e - The form submission event.
     * @param {string} projectId - The ID of the project to delete.
     * @returns {Promise<void>} A promise that resolves when the project is successfully deleted.
     */
    const handleDeletion = async (e, projectId) => {
        e.preventDefault();
        try {
            await axios.delete(`/api/delete-project/${projectId}`);
            NotificationManager.success('Refreshing the page', 'Project Deleted')
            console.log('Deleting this Project: ' + projectId);
            window.location.href = '/new-project';
            setRefresh(true)
        } catch (err) {
            console.log(err);
        }
    };

    // useEffect to fetch data from backend and update the 'projects' state
    useEffect(() => {
        axios.get('/api/collection')
            .then(response => {
                setProjects(response.data);
                setRefresh(true)
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <div>
            {refresh ? (
                <div>
                    <nav>
                        <Navigation />
                    </nav>
                    <h3 className="d-flex flex-wrap flex-row justify-content-center">This page is meant for Mj Burog, this allows him to update projects in the project page</h3>
                        <div className="d-flex flex-row flex-wrap justify-content-center">
                            <form className="d-flex flex-column" onSubmit={handleNewProject}>
                                <textarea type="text" name="title" placeholder="title" value={newProject.title} onChange={(e) => setNewProject({ ...newProject, title: e.target.value })} />
                                <textarea type="text" name="description" placeholder="description (10 words max)" value={newProject.description} onChange={(e) => setNewProject({ ...newProject, description: e.target.value })} />
                                <textarea type="text" name="github" placeholder="github" value={newProject.github} onChange={(e) => setNewProject({ ...newProject, github: e.target.value })} />
                                <input type="file" onChange={(e) => setImage(e.target.files[0])} />
                                <button type="submit">insert project</button>
                            </form>
                        </div>
                    <div className="d-flex flex-row flex-wrap justify-content-evenly">
                        {/* The point of the map is to display the projects linearly in the database from the backend which is called by a useEffect get request to the backend */}
                        {projects.map((project) => (
                            <form key={project._id} onSubmit={(e) => handleDeletion(e, project._id)}>
                                <ul style={{ border: 'solid 2px black' }}>
                                    <img src={`/image/${project.image}`} alt="project" style={{maxWidth: '15vw', height: 'auto'}}/>
                                    <li><p style={{ fontWeight: 'bold' }}>ID:</p> {project._id}</li>
                                    <li><p style={{ fontWeight: 'bold' }}>Title:</p> {project.title}</li>
                                    <li><p style={{ fontWeight: 'bold' }}>Description:</p> {project.description}</li>
                                    <li><p style={{ fontWeight: 'bold' }}>Link:</p> {project.github}</li>
                                    <button type="submit">delete</button>
                                </ul>
                            </form>
                        ))}
                    </div>
                </div>
            ):(
                <div>
                    <nav>
                        <Navigation />
                    </nav>
                    <h3>Loading...</h3>
                </div>
            )}
            <NotificationContainer />
        </div>
    );
}
